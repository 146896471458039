import React from 'react';

function FloatingContactButton() {
    const handleButtonClick = () => {
        const phoneNumber = '+962772100822'; // Replace with your WhatsApp number
        const message = 'Hello, Im interested to work with Tech-Masters'; // Optional message

        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <button className="floating-contact-button bg-custom-orange text-slate-800" onClick={handleButtonClick}>
            <p className="text-xs">
                 Contact The Masters
            </p>
        </button>
    );
}

export default FloatingContactButton;
